// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.
.main-form-row{
    @media screen and (min-width: 768px){
        padding-left: 20px;
    }
}

.reset-date{
    width: 15px;
    position: absolute;
    right: new 0 0 51.976 51.976;
    right: 5px;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    fill: #888;
    opacity: 0;
}
.input-group-date{
    .ti-calendar{
        position: static;
        &:after{
            content: '';
            position: absolute;
            top: 0;
            width: calc(100% - 30px);
            height: 100%;
            left: 0;
            top: 0;
        }
    }
    input{
       pointer-events: none !important;
    }
    &:hover{
        .reset-date{
            opacity: 1;
        }
    }
}