@import 'pages/dashboards/dashboard1';
@import 'pages/authentication/authentication';
@import 'pages/inbox/emails';
@import 'pages/taskboard/taskboard'; 
@import 'pages/forms/typeahead';
@import 'pages/gallery-usercard/gal-card';
@import 'pages/maps/google-vector';
@import 'pages/timeline/timeline';
@import 'pages/nestable/nestable';

//general rules
.clickable{
    cursor: pointer;
}