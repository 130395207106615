/* You can add global styles to this file, and also import other style files */

/* @import "/node_modules/@swimlane/ngx-datatable/release/themes/bootstrap.css";

/* Stili css per ngbDatePicker di Bootstrap */
.form-group.hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}
.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.custom-day.focused {
  background-color: #e6e6e6;
}
.custom-day.range, .custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}
.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}
/* Fine Stili css per ngbDatePicker multiplo di Bootstrap */

/* Stili per la gestione della select */

.angular2-multiselect-noovle-custom > div > div > div > div > ul{
  height: auto !important;
}

/* padding tabella */

  .table-striped{
    padding: 20px;
  }

  /* fix focus bottone */

  .btn-primary:focus, .btn-primary.focus{
    box-shadow: none !important;
  }

  /* cursor pointer dataTable mie-richieste ed approvazioni */

  #tabella-richieste tbody{
    cursor: pointer !important;
   }

   /* #tabella-richieste tbody :hover{
    background-color: #0275d8 !important;
   } */

  #approvazionitable tbody{
    cursor: pointer !important;
  }
  #table-gestione-assunzioni tbody{
    cursor: pointer !important;
  }
  #tabella-richieste-assunzioni tbody, .overflowed-table tbody, #tabella-approvazioni-trasferte tbody{
    cursor: pointer !important;
  }

  /* #approvazionitable tbody :hover{
    background-color: #0275d8 !important;
   }
   #table-gestione-assunzioni tbody :hover{
    background-color: #0275d8 !important;
   }
   #tabella-richieste-assunzioni tbody :hover, .overflowed-table tbody :hover, #tabella-approvazioni-trasferte tbody :hover{
    background-color: #0275d8 !important;
   } */

   #richiesteRecenti tbody ,
   #richieste tbody{
    cursor: pointer !important;
  }

  /* #richiesteRecenti tbody :hover ,
  #richieste tbody :hover {
    background-color: #0275d8 !important;
  }
   */

   /* cursor pointer sul disconetti */
   .dropdown-item{
     cursor: pointer !important;
   }

   /*rimozione margini */
   .fix-margin{
     margin-left: -250px;
     margin-top: 5px;
     position: absolute;
   }

   .fix-margin-burger{
     margin-left: 50px;
   }

   .margin-logo-top{
     margin-top: -10px;
   }

   .margin-logo-right-auth{
     margin-left: 60px;
   }

/* Stili per la gestione responsive dei componenti */
@media screen and (max-width: 767px) {

  #selezionaBeneficiarioInterno{
    margin-left: -1rem;
  }

  #aggiungiBeneficiarioEsterno{
    margin-left: -1rem !important;
    margin-top: 0.5rem;
  }

  .pagination{
    margin-right: 5.5rem !important;
  }

  li.paginate_button.previous {
      display: inline;
  }

  li.paginate_button.next {
      display: inline;
  }

  li.paginate_button {
      display: none;
  }

  .fix-margin-burger{
    margin-left: 0px;
  }

  .margin-logo-right-auth{
    margin-left: 39px;
  }

  .dataTables_wrapper{
    padding: 0px !important;
  }

  .dropdown-menu{
    width: 80% !important;
    transform: translate(35px, 55px) !important;
  }

  .ngb-dp-months{
    display: inline !important;
  }

  .selected-list .c-list{
    overflow-x: hidden;
    height: 25px;
    overflow-y: scroll;
  }
}
.ticket-td{
  max-width: 20vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}  
.font-weight-500{
  font-weight: bold;
}
.color-grey{
  color: #3e5569
}
button[disabled]{
  cursor: auto;
}
.custom-select-ambitofiltertable .cuppa-dropdown .selected-list .c-btn {
  min-height: 32px !important;
  border-width: 0px !important;
}
.custom-select-richieste .selected-list .c-btn{
  min-height: 34px !important;
  border-width: 0px !important;
}
.custom-select-gestione .selected-list .c-btn {
  height: 38px !important;
}
.v-all {
  vertical-align: inherit !important;
}
.pad-0 {
  padding-left: 0;
  padding-right: 0;
}
.click-link{
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  cursor:pointer;
}
.click-link:hover{
  background-color: #dee1e438;
  color: black;
}
.upper-allineate .nav-tabs{
  margin-left: 10px;
}

.no-active-effect:focus{
  box-shadow: none !important;
}
.no-active-effect{
  text-decoration: underline;
}
.no-active-effect:hover{
  text-decoration: underline;
}
.red-error{
  color: rgba(206, 27, 27, 0.815);
}
.fixed-width {
  max-width: 10%;
  width: 10% !important;
  overflow-x: auto;
}

th.fixed-width-10,
td.fixed-width-10 {
  max-width: 10%;
  width: 10% !important;
  overflow-x: auto;
}
th.fixed-width-30,
td.fixed-width-30 {
  max-width: 30%;
  width: 30% !important;
  overflow-x: auto;
}

.dtr-details > .fixed-width {
  max-width: none;
  width: auto !important;
}

.no-padding{
  padding: 0;
}
.overflowed-table {
  table-layout: fixed;
}

.overflowed-table ::-webkit-scrollbar {
  height: 7px;
}

.overflowed-table ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.overflowed-table ::-webkit-scrollbar-thumb {
  background: #888;
}

.overflowed-table ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

table.dataTable thead th, tfoot th {
  font-weight: 400!important;
  color:#212529!important;
}

table.dataTable tbody td{
  padding: 1rem!important;
  vertical-align: middle !important;
}

.paginate_button {
  padding: 0px !important;
  border: none !important;
  margin-left: 0px !important;
}

.paginate_button a {
  min-width: 2.25em !important;
}

.paginate_button a :hover {
  border: none !important;
  background: linear-gradient(to bottom, #585858 0%, #000 100%) !important;
}

.page-link {
  margin-left: -1px !important;
}

.page-link :hover {
  border: none !important;
}


.btn-default:hover {
  color: #fff;
  background-color: #2d8be9;
  border-color: #4798e8;
}

